import { ChainId, ERC20Token } from '@pancakeswap/sdk'
import { bscTokens } from './56'
import { bethTokens } from './1605'

export const USD: Record<ChainId, ERC20Token> = {
  [ChainId.BSC]: bscTokens.usdt, // todo: add proper BSC USDT
  [ChainId.BETH]: bethTokens.usdt, // todo: add proper BSC USDT
}

export const ICE: Record<ChainId, ERC20Token> = {
  [ChainId.BSC]: bscTokens.bnb, // todo: add proper BSC ICE
  [ChainId.BETH]: bethTokens.robo, // todo: add proper BSC ICE
}
