import { Flex, Button, Text, QuestionHelper } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { useGasPriceManager } from '../../../state/user/hooks'
import { GAS_PRICE_GWEI, GAS_PRICE } from '../../../state/types'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  border-radius: 0;
  font-size: 10px;
  font-weight: 500;
  min-width: 5rem;
`

const GasSettings = () => {
  const { t } = useTranslation()
  const [gasPrice, setGasPrice] = useGasPriceManager()

  return (
    <Flex flexDirection="column" gap="12px">
      <Flex alignItems="center">
        <Text small bold>
          {t('Default Transaction Speed (GWEI)')}
        </Text>
        <QuestionHelper
          text={t(
            'Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees',
          )}
          placement="top-start"
          ml="4px"
        />
      </Flex>
      <Flex flexWrap="wrap" gap="8px">
        <StyledButton
          scale="xs"
          onClick={() => setGasPrice(GAS_PRICE_GWEI.rpcDefault)}
          variant={gasPrice === GAS_PRICE_GWEI.rpcDefault ? 'primary' : 'tertiary'}
        >
          Auto
          {/* {t('RPC Default')} */}
        </StyledButton>
        <StyledButton
          scale="xs"
          onClick={() => setGasPrice(GAS_PRICE_GWEI.default)}
          variant={gasPrice === GAS_PRICE_GWEI.default ? 'primary' : 'tertiary'}
        >
          {t('Standard (%gasPrice%)', { gasPrice: GAS_PRICE.default })}
        </StyledButton>
        <StyledButton
          scale="xs"
          onClick={() => setGasPrice(GAS_PRICE_GWEI.fast)}
          variant={gasPrice === GAS_PRICE_GWEI.fast ? 'primary' : 'tertiary'}
        >
          {t('Fast (%gasPrice%)', { gasPrice: GAS_PRICE.fast })}
        </StyledButton>
        <StyledButton
          scale="xs"
          onClick={() => setGasPrice(GAS_PRICE_GWEI.instant)}
          variant={gasPrice === GAS_PRICE_GWEI.instant ? 'primary' : 'tertiary'}
        >
          {t('Instant (%gasPrice%)', { gasPrice: GAS_PRICE.instant })}
        </StyledButton>
      </Flex>
    </Flex>
  )
}

export default GasSettings
