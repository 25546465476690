import styled from 'styled-components';
import Text from '../Text/Text';
import { tags, scales, HeadingProps } from './types';

const style = {
  [scales.SM]: {
    fontSize: '16px',
    fontSizeLg: '16px',
  },
  [scales.MD]: {
    fontSize: '20px',
    fontSizeLg: '20px',
  },
  [scales.LG]: {
    fontSize: '24px',
    fontSizeLg: '32px',
  },
  [scales.XL]: {
    fontSize: '32px',
    fontSizeLg: '40px',
  },
  [scales.XXL]: {
    fontSize: '54px',
    fontSizeLg: '64px',
  },
};

const Heading = styled(Text).attrs({ bold: true })<HeadingProps>`
  font-size: ${({ scale }) => style[scale || scales.MD].fontSize};
  font-weight: 700;
  line-height: 1.5;
  ${({ withShadow }) =>
    withShadow && {
      color: '#fefefe',
      textShadow: '0px 0px 4px #fff',
    }}

  ${({ theme }) => theme.mediaQueries.lg} {
    font-size: ${({ scale }) => style[scale || scales.MD].fontSizeLg};
  }
`;

Heading.defaultProps = {
  as: tags.H2,
  withShadow: true,
};

export default Heading;
