import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M15.8333 3.33398C16.2754 3.33398 16.6993 3.50958 17.0118 3.82214C17.3244 4.1347 17.5 4.55862 17.5 5.00065V5.83398H12.5C11.3949 5.83398 10.3351 6.27297 9.55372 7.05437C8.77232 7.83577 8.33333 8.89558 8.33333 10.0007C8.33333 11.1057 8.77232 12.1655 9.55372 12.9469C10.3351 13.7283 11.3949 14.1673 12.5 14.1673H17.5V15.0007C17.5 15.4427 17.3244 15.8666 17.0118 16.1792C16.6993 16.4917 16.2754 16.6673 15.8333 16.6673H4.16667C3.72464 16.6673 3.30072 16.4917 2.98816 16.1792C2.67559 15.8666 2.5 15.4427 2.5 15.0007V5.00065C2.5 4.55862 2.67559 4.1347 2.98816 3.82214C3.30072 3.50958 3.72464 3.33398 4.16667 3.33398H15.8333ZM16.6667 7.50065C17.1087 7.50065 17.5326 7.67625 17.8452 7.98881C18.1577 8.30137 18.3333 8.72529 18.3333 9.16732V10.834C18.3333 11.276 18.1577 11.6999 17.8452 12.0125C17.5326 12.3251 17.1087 12.5007 16.6667 12.5007H12.5C11.837 12.5007 11.2011 12.2373 10.7322 11.7684C10.2634 11.2996 10 10.6637 10 10.0007C10 9.33761 10.2634 8.70173 10.7322 8.23288C11.2011 7.76404 11.837 7.50065 12.5 7.50065H16.6667ZM12.5 9.16732C12.279 9.16732 12.067 9.25512 11.9107 9.4114C11.7545 9.56768 11.6667 9.77964 11.6667 10.0007C11.6667 10.2217 11.7545 10.4336 11.9107 10.5899C12.067 10.7462 12.279 10.834 12.5 10.834C12.721 10.834 12.933 10.7462 13.0893 10.5899C13.2455 10.4336 13.3333 10.2217 13.3333 10.0007C13.3333 9.77964 13.2455 9.56768 13.0893 9.4114C12.933 9.25512 12.721 9.16732 12.5 9.16732Z"
        fill="#FEFEFE"
      />
    </Svg>
  );
};

export default Icon;
