import { StaticJsonRpcProvider } from '@ethersproject/providers'

export const BSC_PROD_NODE = process.env.NEXT_PUBLIC_NODE_PRODUCTION || 'https://bsc.nodereal.io'

export const BETH_PROD_NODE = 'https://bsc-testnet-rpc.publicnode.com'

export const bscRpcProvider = new StaticJsonRpcProvider(BSC_PROD_NODE)

export const BethRpcProvider = new StaticJsonRpcProvider(BETH_PROD_NODE)

export default null
