import { ChainId, ERC20Token } from '@pancakeswap/sdk'

export const bethTokens = {
  robo: new ERC20Token(
    ChainId.BETH,
    '0xDC558b6848B6A2378D1b4bAA8E76EA78552C113d',
    18,
    'ROBO',
    'ROBO Token',
    'https://robodexswap.io',
  ),
  usdt: new ERC20Token(
    ChainId.BETH,
    '0x5E0bA1346C1ED0Caf1f8925d403375FC27D665B7',
    18,
    'USDT',
    'USD Tether',
    'https://tether.to',
  ),
  weth: new ERC20Token(
    ChainId.BETH,
    '0x01D9F975844BA575667D759712095368a1552999',
    18,
    'WETH',
    'Wrapped Ether',
    'https://robodexswap.io',
  ),
  wbeth: new ERC20Token(
    ChainId.BETH,
    '0x6348B0B57416EB3F7e8fF9697cC281100A7994F0',
    18,
    'WBETH',
    'Wrapped Betherance',
    'https://www.binance.com/',
  ),
  ozzora: new ERC20Token(
    ChainId.BETH,
    '0x4EcA56058CACcb6C6A1eda3F2D68Aca705805E0c',
    18,
    'OZZO',
    'Token OZZORA',
    'https://www.binance.com/',
  ),
}
