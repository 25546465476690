import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { Box, Flex } from '../../../../components/Box';
import { ChevronDownIcon } from '../../../../components/Svg';
import { UserMenuProps, variants } from './types';
import MenuIcon from './MenuIcon';
import { UserMenuItem } from './styles';

export const StyledUserMenu = styled(Flex)`
  align-items: center;
  border-radius: 0px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  height: 42px;
  padding: 9px 16px 9px 40px;
  position: relative;
  border: 0.5px solid var(--Color-Primary, #5b46f6);
  background: var(--opacity-purple-10, rgba(91, 70, 246, 0.1));

  &:hover {
    opacity: 0.65;
  }
`;

export const LabelText = styled.div`
  color: ${({ theme }) => theme.colors.text};
  display: block;
  font-weight: 600;

  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
    // margin-left: 8px;
    // margin-right: 4px;
  }
`;

const Menu = styled.div<{ isOpen: boolean }>`
  background-color: #101014;
  border: 1px solid #5b46f6;

  padding-top: 4px;
  overflow: hidden;
  pointer-events: auto;
  width: 280px;
  visibility: visible;
  z-index: 1001;

  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}

  ${UserMenuItem}:first-child {
    border-radius: 8px 8px 0 0;
  }

  ${UserMenuItem}:last-child {
    border-radius: 0 0 8px 8px;
  }
`;

const UserMenu: React.FC<UserMenuProps> = ({
  account,
  text,
  avatarSrc,
  variant = variants.DEFAULT,
  children,
  disabled,
  placement = 'bottom-end',
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
  const accountEllipsis = account ? `${account.substring(0, 2)}...${account.substring(account.length - 4)}` : null;
  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: 'fixed',
    placement,
    // modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
  });

  useEffect(() => {
    const showDropdownMenu = () => {
      setIsOpen(true);
    };

    const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
      const target = evt.target as Node;
      if (target && !tooltipRef?.contains(target)) {
        setIsOpen(false);
        evt.stopPropagation();
      }
    };

    targetRef?.addEventListener('mouseenter', showDropdownMenu);
    targetRef?.addEventListener('mouseleave', hideDropdownMenu);

    return () => {
      targetRef?.removeEventListener('mouseenter', showDropdownMenu);
      targetRef?.removeEventListener('mouseleave', hideDropdownMenu);
    };
  }, [targetRef, tooltipRef, setIsOpen]);

  return (
    <Flex alignItems="center" height="100%" ref={setTargetRef} {...props}>
      <StyledUserMenu
        width="100%"
        onTouchStart={() => {
          setIsOpen((s) => !s);
        }}
      >
        <Flex alignItems="center" gap="8px">
          <MenuIcon avatarSrc={avatarSrc} variant={variant} />
          <LabelText title={typeof text === 'string' ? text || account : account}>{text || accountEllipsis}</LabelText>
        </Flex>
        {!disabled && <ChevronDownIcon color="text" width="24px" />}
      </StyledUserMenu>
      {!disabled && (
        <Menu style={styles.popper} ref={setTooltipRef} {...attributes.popper} isOpen={isOpen}>
          <Box onClick={() => setIsOpen(false)}>{children?.({ isOpen })}</Box>
        </Menu>
      )}
    </Flex>
  );
};

export default UserMenu;
