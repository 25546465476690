/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Flex, IconButton, CogIcon, useModal } from '@pancakeswap/uikit'
import SettingsModal from './SettingsModal'
import { IconBTN } from 'views/Swap/components/CurrencyInputHeader'

type Props = {
  color?: string
  mr?: string
  mode?: string
  icon: React.ReactNode
  isHeader?: boolean
}

const GlobalSettings = ({ color, mr = '8px', mode, icon, isHeader = false }: Props) => {
  const [onPresentSettingsModal] = useModal(<SettingsModal mode={mode} />)

  return (
    <>
      {isHeader ? (
        <div onClick={onPresentSettingsModal} id={`open-settings-dialog-button-${mode}`} aria-label="Settings">
          {icon}
        </div>
      ) : (
        <IconBTN onClick={onPresentSettingsModal} id={`open-settings-dialog-button-${mode}`} aria-label="Settings">
          {icon}
        </IconBTN>
      )}
    </>
  )
}

export default GlobalSettings
