import { Svg, SvgProps } from '@pancakeswap/uikit'

export const ChartIcon = (props: SvgProps) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M21 11.25H19.5V6.75C19.5 6.35218 19.342 5.97064 19.0607 5.68934C18.7794 5.40804 18.3978 5.25 18 5.25H14.25C13.8522 5.25 13.4706 5.40804 13.1893 5.68934C12.908 5.97064 12.75 6.35218 12.75 6.75V11.25H11.25V4.5C11.25 4.10218 11.092 3.72064 10.8107 3.43934C10.5294 3.15804 10.1478 3 9.75 3H6C5.60218 3 5.22064 3.15804 4.93934 3.43934C4.65804 3.72064 4.5 4.10218 4.5 4.5V11.25H3C2.80109 11.25 2.61032 11.329 2.46967 11.4697C2.32902 11.6103 2.25 11.8011 2.25 12C2.25 12.1989 2.32902 12.3897 2.46967 12.5303C2.61032 12.671 2.80109 12.75 3 12.75H4.5V19.5C4.5 19.8978 4.65804 20.2794 4.93934 20.5607C5.22064 20.842 5.60218 21 6 21H9.75C10.1478 21 10.5294 20.842 10.8107 20.5607C11.092 20.2794 11.25 19.8978 11.25 19.5V12.75H12.75V17.25C12.75 17.6478 12.908 18.0294 13.1893 18.3107C13.4706 18.592 13.8522 18.75 14.25 18.75H18C18.3978 18.75 18.7794 18.592 19.0607 18.3107C19.342 18.0294 19.5 17.6478 19.5 17.25V12.75H21C21.1989 12.75 21.3897 12.671 21.5303 12.5303C21.671 12.3897 21.75 12.1989 21.75 12C21.75 11.8011 21.671 11.6103 21.5303 11.4697C21.3897 11.329 21.1989 11.25 21 11.25ZM9.75 19.5H6V4.5H9.75V19.5ZM18 17.25H14.25V6.75H18V17.25Z"
        fill="#FEFEFE"
      />
    </Svg>
  )
}
