import IceChain from '../ice-chain'

const bethScanExplorer = { name: 'Bethscan', url: 'https://bethscan.io' }

export const beth: IceChain = {
  id: 1605,
  name: 'Betherance',
  features: ['swap', 'staking', 'locks'],
  network: 'BETH',
  rpcUrls: {
    public: 'https://rpc.bethscan.io',
    default: 'https://rpc.bethscan.io',
  },
  blockExplorers: {
    default: bethScanExplorer,
    etherscan: bethScanExplorer,
  },
  nativeCurrency: {
    name: 'Betherance',
    symbol: 'BETH',
    decimals: 18,
  },
  blockInterval: 5,
  multicall: {
    address: '0xa0186CaD505212Ec15946D177EA4A74494cCff83',
    blockCreated: 43202987,
  },
  swap: {
    factoryAddress: '0x7eeC86c0d7300F9c9a634D0a88f1de35a6790857',
    initCodeHash: '0x6c4d683a0d60080f85c3568b38091bcd20111b695602aa1cb64e5f2b46a9ddf8',
  },
  wrappedNative: {
    address: '0x6348B0B57416EB3F7e8fF9697cC281100A7994F0',
    decimals: 18,
    symbol: 'WBETH',
    name: 'Wrapped Betherance',
  },
  locks: {
    factoryAddress: '0x3BE4a07bAD537F0A291508077991d5e53c787613',
  },
}
