import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon = (props: SvgProps) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M9.32 21.3333L18.6667 21.3333L18.6667 18.6666L9.32 18.6666L9.32 14.6666L4 20L9.32 25.3333L9.32 21.3333ZM28 12L22.68 6.66663L22.68 10.6666L13.3333 10.6666L13.3333 13.3333L22.68 13.3333L22.68 17.3333L28 12ZM9.32 21.3333L18.6667 21.3333L18.6667 18.6666L9.32 18.6666L9.32 14.6666L4 20L9.32 25.3333L9.32 21.3333ZM28 12L22.68 6.66663L22.68 10.6666L13.3333 10.6666L13.3333 13.3333L22.68 13.3333L22.68 17.3333L28 12Z"
        fill="#FBFCFF"
      />
    </Svg>
  );
};

export default Icon;
