import { m as motion } from 'framer-motion';
import styled from 'styled-components';
import { background, border, layout, position, space, color, flexbox, grid } from 'styled-system';
import { FlexProps } from './types';

export const MotionBox = styled(motion.div)<FlexProps>`
  ${background}
  ${border}
  ${flexbox}
  ${grid}
  ${layout}
  ${position}
  ${space}
  ${({ gap }) => gap && `gap: ${gap};`}
`;

const Box = styled.div<FlexProps>`
  ${background}
  ${border}
  ${flexbox}
  ${grid}
  ${layout}
  ${position}
  ${space}
  ${color}
  ${({ gap }) => gap && `gap: ${gap};`}
`;

export default Box;
