import { ElementType, ReactNode } from 'react'
import { LayoutProps, SpaceProps } from 'styled-system'
import type { PolymorphicComponentProps } from '@pancakeswap/uikit/src/util/polymorphic'

export const scales = {
  MD: 'md',
  SM: 'sm',
  XS: 'xs',
} as const

export type Scale = typeof scales[keyof typeof scales]

export interface BaseButtonRoboticProps extends LayoutProps, SpaceProps {
  as?: 'a' | 'button' | ElementType
  external?: boolean
  isLoading?: boolean
  scale?: Scale
  disabled?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
  decorator?: {
    backgroundColor?: string
    color?: string
    text: string
    direction?: 'left' | 'right'
  }
  rounded?: boolean
  isBlock?: boolean
  isWeight?: boolean
  withIcon?: boolean
  shouldResponsive?: boolean
}

export type ButtonRoboticProps<P extends ElementType = 'button'> = PolymorphicComponentProps<P, BaseButtonRoboticProps>
