import styled from 'styled-components';
import { PolymorphicComponent } from '../../util/polymorphic';
import Button from './Button';
import { BaseButtonProps } from './types';

const IconButton: PolymorphicComponent<BaseButtonProps, 'button'> = styled(Button)<BaseButtonProps>`
  padding: 0;
  border-radius: 0.5rem;
  border: 0.03125rem solid ${({ theme }) => theme.colors.primary};
  background: rgba(91, 70, 246, 0.1) !important;
  width: ${({ scale }) => (scale === 'xs' ? '32px' : scale === 'sm' ? '40px' : '48px')};
  height: ${({ scale }) => (scale === 'xs' ? '32px' : scale === 'sm' ? '40px' : '48px')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

export default IconButton;
