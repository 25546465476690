import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <svg
    style={{
      height: '24px',
      width: '100%',
      top: 2,
    }}
    viewBox="0 0 20 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.797 1.922v20.906h1.406V1.922H5.797zm-.844.907l-3.61 2.557v1.031l3.61-2.554V2.829zm3.094 0v1.033l15.131 10.716h.478v-.689L8.046 2.83zM4.953 4.62l-3.61 3.385v1.158l3.61-3.384V4.62zm3.094 0V5.78l9.384 8.798h1.238L8.047 4.621zm0 2.335V8.57l3.698 6.01h.994L8.047 6.955zm-3.094.005l-3.61 5.864v1.608l3.61-5.864V6.96zm-3.61 8.46v1.407h3.61v-1.406h-3.61zm6.704 0v1.407h15.61v-1.406H8.046z"
      fill="url(#paint0_linear_994_27184)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_994_27184"
        x1={35.3625}
        y1={-18.4244}
        x2={-8.61347}
        y2={-15.5442}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5B46F6" />
        <stop offset={1} stopColor="#5B46F6" />
      </linearGradient>
    </defs>
  </svg>
);

export default Icon;
