import { Swap, Flex, IconButton, NotificationDot, useModal, Text, Box } from '@pancakeswap/uikit'
import TransactionsModal from 'components/App/Transactions/TransactionsModal'
import GlobalSettings from 'components/Menu/GlobalSettings'
// import RefreshIcon from 'components/Svg/RefreshIcon'
import { ReactElement, useCallback, useContext } from 'react'
import { useExpertModeManager } from 'state/user/hooks'
import styled from 'styled-components'
import { SettingsMode } from '../../../components/Menu/GlobalSettings/types'
import { SwapFeaturesContext } from '../SwapFeaturesContext'
import { ChartIcon, ChartActiveIcon, HistoryIcon, SettingIcon } from '../../../../public/icon'

interface Props {
  title: string | ReactElement
  subtitle?: string
  noConfig?: boolean
  setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed?: boolean
  hasAmount: boolean
  onRefreshPrice: () => void
  mutateAkkaRoute?: () => void
}

const ColoredIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.textSubtle};
`

export const IconBTN = styled.button`
  display: grid;
  place-items: center;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0.5rem;
  border: 0.5px solid ${({ theme }) => theme.colors.primary};
  background: rgba(91, 70, 246, 0.1);
  -webkit-appearance: button;
  background-image: none;
  text-transform: none;
  cursor: pointer;
`

const CurrencyInputHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  subtitle,
  hasAmount,
  onRefreshPrice,
  mutateAkkaRoute,
}) => {
  // const { isChartSupported, isChartDisplayed, setIsChartDisplayed } = useContext(SwapFeaturesContext)
  const { isChartDisplayed, setIsChartDisplayed } = useContext(SwapFeaturesContext)
  const isChartSupported = false

  const [expertMode] = useExpertModeManager()
  const toggleChartDisplayed = () => {
    setIsChartDisplayed((currentIsChartDisplayed) => !currentIsChartDisplayed)
  }
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)
  const handleOnClick = useCallback(() => {
    mutateAkkaRoute?.()
    onRefreshPrice?.()
  }, [onRefreshPrice])

  return (
    <Swap.CurrencyInputHeader
      title={
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Flex gap="8px">
            {isChartSupported && setIsChartDisplayed && (
              <IconBTN type="button" onClick={toggleChartDisplayed} aria-label="Toggle chart">
                <ChartIcon />
              </IconBTN>
            )}
            <Box size={40} />
          </Flex>
          <Swap.CurrencyInputHeaderTitle>{title}</Swap.CurrencyInputHeaderTitle>
          <Flex gap="8px">
            <IconBTN type="button" onClick={onPresentTransactionsModal} aria-label="Toggle chart">
              <HistoryIcon />
            </IconBTN>
            <NotificationDot show={expertMode}>
              <GlobalSettings mode={SettingsMode.SWAP_LIQUIDITY} aria-label="Settings" icon={<SettingIcon />} />
            </NotificationDot>
          </Flex>
        </Flex>
      }
      subtitle={<Swap.CurrencyInputHeaderSubTitle>{subtitle}</Swap.CurrencyInputHeaderSubTitle>}
    />
  )
}

export default CurrencyInputHeader
