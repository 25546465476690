import { bsc } from './bsc'
import { beth } from './beth'
import { matic } from './matic'

export const chainMap = {
  bsc,
  beth,
  matic,
}

export const chains = Object.values(chainMap)

export const getChain = (chainId: number) => {
  return chains.find((chain) => chain.id === chainId)
}

export enum ChainId {
  BSC = bsc.id,
  BETH = beth.id,
  MATIC = matic.id,
}
