import { scales, variants } from './types';

export const scaleVariants = {
  [scales.LG]: {
    height: '3.375rem',
    padding: '0 16px',
    fontSize: '1rem',
  },
  [scales.MD]: {
    height: '2.875rem',
    padding: '0 16px',
  },
  [scales.SM]: {
    height: '40px',
    padding: '0 16px',
  },
  [scales.XS]: {
    height: '32px',
    fontSize: '12px',
    padding: '0 8px',
  },
};

export const styleVariants = {
  [variants.PRIMARY]: {
    backgroundColor: '#5B46F6',
    color: 'contrast',
  },
  [variants.SECONDARY]: {
    backgroundColor: 'transparent',
    border: '2px solid',
    borderColor: '#5B46F6 ',
    boxShadow: 'none',
    color: '#5B46F6 ',
    ':disabled': {
      backgroundColor: 'transparent',
    },
  },
  [variants.TERTIARY]: {
    backgroundColor: 'tertiary',
    boxShadow: 'none',
    color: '#5B46F6 ',
  },
  [variants.SUBTLE]: {
    backgroundColor: 'textSubtle',
    color: 'backgroundAlt',
  },
  [variants.DANGER]: {
    backgroundColor: 'failure',
    color: '#ffffff ',
  },
  [variants.SUCCESS]: {
    backgroundColor: 'success',
    color: '#ffffff ',
  },
  [variants.TEXT]: {
    backgroundColor: 'transparent',
    color: '#5B46F6 ',
    boxShadow: 'none',
  },
  [variants.LIGHT]: {
    borderRadius: '0px !important',
    border: '0.5px solid #5B46F6',
    background: 'rgba(91, 70, 246, 0.10)',
    color: '#FEFEFE',
    fontStyle: 'normal',
    fontWeight: '600',
  },
};
