import styled from 'styled-components'
import { Text, Box, CheckmarkIcon, CloseIcon, Flex, Grid, Link, Image } from '@pancakeswap/uikit'
import { getBlockExploreLink } from '../../../utils'
import { TransactionDetails } from 'state/transactions/reducer'
import CircleLoader from '../../Loader/CircleLoader'

const LinkExternal = styled(Link)`
  position: absolute;
  inset: 0;
  width: 100%;
`

const IconWrapper = styled(Grid)<{ pending: boolean; success?: boolean }>`
  place-items: center;
  color: ${({ pending, success, theme }) =>
    pending ? theme.colors.primary : success ? theme.colors.success : theme.colors.failure};
`

function getTimeCategory(timestamp: number) {
  const now = new Date()
  const date = new Date(timestamp)

  const msInDay = 24 * 60 * 60 * 1_000
  const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()
  const startOfWeek = startOfToday - now.getDay() * msInDay

  if (date.getTime() >= startOfToday) {
    return 'Today'
  }
  if (date.getTime() >= startOfWeek) {
    return 'This week'
  }
  return 'Older'
}

export default function Transaction({ tx, chainId }: { tx: TransactionDetails; chainId: number }) {
  const summary = tx?.summary
  const pending = !tx?.receipt
  const success = !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined')

  const subtitle = `${tx?.translatableSummary.data.inputAmount} ${tx?.translatableSummary.data.inputSymbol} for min. ${tx?.translatableSummary.data.outputAmount} ${tx?.translatableSummary.data.outputSymbol}`

  if (!chainId) return null

  return (
    <Flex position="relative" gap=".5rem" alignItems="center" justifyContent="space-between">
      {/* <Image src={`/images/tokens/${tx?.from}.png`} width={24} height={24} alt={tx?.type} /> */}
      <Box>
        <Text fontSize={14} bold textTransform="capitalize">
          {tx?.type ?? '-'}
        </Text>
        <Text fontSize={10} color="defaultColor" ellipsis>
          {subtitle ?? tx?.hash}
        </Text>
      </Box>
      <IconWrapper pending={pending} success={success}>
        {pending ? <CircleLoader /> : success ? <CheckmarkIcon color="success" /> : <CloseIcon color="failure" />}
      </IconWrapper>
      <LinkExternal
        href={getBlockExploreLink(tx.hash, 'transaction', chainId)}
        target="_blank"
        rel="noreferrer noopener"
      />
    </Flex>
  )
}

// import styled from 'styled-components'
// import { CloseIcon, LinkExternal, Flex, Text, Box, CheckmarkCircleFillIcon, NewCheckFillIcon } from '@pancakeswap/uikit'
// import { getBlockExploreLink } from '../../../utils'
// import { TransactionDetails } from 'state/transactions/reducer'
// import CircleLoader from '../../Loader/CircleLoader'
// import historyTransaction from '../../../style/HistoryTransaction.module.css'

// import Image from 'next/image'

// const TransactionState = styled.div<{ pending: boolean; success?: boolean }>`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   text-decoration: none !important;
//   border-radius: 0.5rem;
//   padding: 0.25rem 0rem;
//   font-weight: 500;
//   font-size: 0.825rem;
//   color: ${({ theme }) => theme.colors.primary};
// `

// const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
//   color: ${({ pending, success, theme }) =>
//     pending ? theme.colors.primary : success ? theme.colors.success : theme.colors.failure};
// `

// const symbolToken = {
//   swich: '/images/transaction/swich.png',
//   usdt: '/images/transaction/usdt.png',
//   babyDoge: '/images/transaction/babyDoge.png',
//   floki: '/images/transaction/floki.png',
//   link: '/images/transaction/link.png',
//   wbnb: '/images/transaction/wbnb.png',
//   weth: '/images/transaction/weth.png',
//   wbtc: '/images/transaction/wbtc.png',
//   tbnb: '/images/transaction/tbnb.png',
//   robo: '/images/transaction/robo.png',
// }

// const getInputImage = (symbol: string | undefined): JSX.Element | null => {
//   const lowercasedSymbol = symbol?.toLocaleString().toLowerCase()
//   return symbolToken[lowercasedSymbol] ? (
//     <Image alt="images" src={symbolToken[lowercasedSymbol]} width={32} height={32} />
//   ) : null
// }

// export default function Transaction({ tx, chainId }: { tx: TransactionDetails; chainId: number }) {
//   const summary = tx?.summary
//   const pending = !tx?.receipt
//   const success = !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined')

//   if (!chainId) return null

//   const getTimeCategory = (timestamp: number): string => {
//     const date = new Date(timestamp)
//     const today = new Date()

//     // Check if the date is today
//     if (
//       date.getDate() === today.getDate() &&
//       date.getMonth() === today.getMonth() &&
//       date.getFullYear() === today.getFullYear()
//     ) {
//       return 'Today'
//     }

//     // Check if the date is within the current week
//     const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
//     const timeDifference = Math.round(Math.abs((today.getTime() - date.getTime()) / oneDay))
//     if (timeDifference <= 6 && date.getDay() >= today.getDay()) {
//       return 'This week'
//     }

//     // If not today or this week, consider it older
//     return 'Older'
//   }

//   return (
//     <TransactionState
//       // style={{
//       //   border: '1px solid rgba(255,255,255,0.05)',
//       //   padding: '12px',
//       //   marginBottom: '20px',
//       //   borderRadius: '12px',
//       //   background: '#191F39',
//       // }}
//       pending={pending}
//       success={success}
//     >
//       {/* <LinkExternal href={getBlockExploreLink(tx.hash, 'transaction', chainId)}>{summary ?? tx.hash}</LinkExternal>
//       <IconWrapper pending={pending} success={success}>
//         {pending ? <CircleLoader /> : success ? <CheckmarkIcon color="success" /> : <CloseIcon color="failure" />}
//       </IconWrapper> */}
//       <div className={historyTransaction.detailWrapper}>
//         <div className={historyTransaction.wrapperTransaction}>
//           {tx.type.toLowerCase() === 'wrap' ? (
//             <div className={historyTransaction.fronImage}>
//               {getInputImage(tx?.translatableSummary?.data?.wrap as string)}
//             </div>
//           ) : (
//             <div className={historyTransaction.stackedImage}>
//               <div className={historyTransaction.backImage}>
//                 {getInputImage(
//                   tx?.translatableSummary?.data?.inputSymbol
//                     ? String(tx?.translatableSummary?.data?.inputSymbol)
//                     : String(tx?.translatableSummary?.data?.symbolA),
//                 )}
//               </div>
//               <div className={historyTransaction.fronImage} style={{ zIndex: 2 }}>
//                 {getInputImage(
//                   tx?.translatableSummary?.data?.outputSymbol
//                     ? String(tx?.translatableSummary?.data?.outputSymbol)
//                     : String(tx?.translatableSummary?.data?.symbolB),
//                 )}
//               </div>
//             </div>
//           )}
//           <Flex flexDirection="column" gap="1px">
//             {/* <h1
//               style={{
//                 fontSize: '12px',
//                 color: '#90A3BF',
//               }}
//             >
//               {getTimeCategory(tx.confirmedTime)}
//             </h1> */}

//             <Text textTransform="capitalize" fontSize={14} fontWeight={600} color="#FBFCFF">
//               {tx.type}
//             </Text>
//             <Text fontSize={12} fontWeight={400} color="#ADB1B8">
//               {pending ? 'Please confirm in Metamask' : 'Transaction Success'}
//             </Text>
//           </Flex>
//         </div>
//         <IconWrapper pending={pending} success={success}>
//           {pending ? (
//             <CircleLoader />
//           ) : success ? (
//             <NewCheckFillIcon color="success" fill="contrast" />
//           ) : (
//             <CloseIcon color="failure" />
//           )}
//         </IconWrapper>
//       </div>
//       {/* <Flex justifyContent="space-between" alignItems="center" width="100%">
//         <Flex justifyContent="space-between">
//           {tx.translatableSummary?.data?.inputSymbol.toLocaleString().toLowerCase() === 'beth' ? (
//             <Image alt="images" src="/images/chains/575757.png" width={10} height={50} priority />
//           ) : null}
//           <Flex flexDirection="column" gap="4px">

//             <Text textTransform="capitalize" fontSize={16} fontWeight={600} color="textSubtle">
//               {tx.type}
//             </Text>
//             <Text fontSize={16} fontWeight={400} color="textSubtle2">
//               {pending ? 'Please confirm in Metamask' : 'Transaction Success'}
//             </Text>
//           </Flex>
//         </Flex>

//         <IconWrapper pending={pending} success={success}>
//           {pending ? (
//             <CircleLoader />
//           ) : success ? (
//             <CheckmarkCircleFillIcon color="success" fill="contrast" />
//           ) : (
//             <CloseIcon color="failure" />
//           )}
//         </IconWrapper>
//       </Flex> */}
//     </TransactionState>
//   )
// }
