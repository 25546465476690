import { ChainId } from '@pancakeswap/sdk'
import FarmsBethPriceHelper from './1605'

// todo: make dynamic
export const getFarmsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.BETH:
      return FarmsBethPriceHelper
    default:
      return []
  }
}
