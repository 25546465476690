import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2071 7.79289C14.5976 8.18342 14.5976 8.81658 14.2071 9.20711L11.4142 12L14.2071 14.7929C14.5976 15.1834 14.5976 15.8166 14.2071 16.2071C13.8166 16.5976 13.1834 16.5976 12.7929 16.2071L9.29289 12.7071C8.90237 12.3166 8.90237 11.6834 9.29289 11.2929L12.7929 7.79289C13.1834 7.40237 13.8166 7.40237 14.2071 7.79289Z"
        fill="#B3B4B7"
      />
    </Svg>
  );
};

export default Icon;
