import { getFullDecimalMultiplier } from '@pancakeswap/utils/getFullDecimalMultiplier'
import { getChain } from '@icecreamswap/constants'
// TODO: network change to blocktime
export const BETH_BLOCK_TIME = 15

const getPathname = () => {
  if (typeof window !== 'undefined') {
    return window.location.origin
  }
  return null
}
const pathname = getPathname()

// export const BASE_URL = 'https://robodexswap.io'
export const BASE_URL = pathname || 'https://devswap.robodexswap.io'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const DEFAULT_TOKEN_DECIMAL = getFullDecimalMultiplier(18)
export const BOOSTED_FARM_GAS_LIMIT = 1000000
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
export const SECONDS_PER_YEAR = 365 * 24 * 60 * 60

export const blocksPerYear = (chainId: number) => {
  return SECONDS_PER_YEAR / getChain(chainId).blockInterval
}
