import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M9.41655 11.9167L7.24989 9.75004C6.986 9.48615 6.92711 9.18421 7.07322 8.84421C7.21933 8.50421 7.47961 8.33393 7.85405 8.33337H12.1457C12.5207 8.33337 12.7813 8.50365 12.9274 8.84421C13.0735 9.18476 13.0143 9.48671 12.7499 9.75004L10.5832 11.9167C10.4999 12 10.4096 12.0625 10.3124 12.1042C10.2152 12.1459 10.111 12.1667 9.99989 12.1667C9.88878 12.1667 9.78461 12.1459 9.68739 12.1042C9.59017 12.0625 9.49989 12 9.41655 11.9167Z"
      fill="white"
    />
  </Svg>
);

export default Icon;
