import React, { useContext } from 'react';
import { MenuContext } from '../../widgets/Menu/context';
import { Flex } from '../Box';
import AnimatedIconComponent from '../Svg/AnimatedIconComponent';
import { StyledBottomNavItem, StyledBottomNavText } from './styles';
import { BottomNavItemProps } from './types';

const BottomNavItem: React.FC<React.PropsWithChildren<BottomNavItemProps>> = ({
  label,
  icon,
  fillIcon,
  href,
  showItemsOnMobile = false,
  isActive = false,
  disabled = false,
  ...props
}) => {
  const { linkComponent } = useContext(MenuContext);
  const bottomNavItemContent = (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%" gap="4px">
      {icon && (
        <AnimatedIconComponent
          icon={icon}
          fillIcon={fillIcon}
          height="24px"
          width="24px"
          color={isActive ? 'primary' : '#71757A'}
          isActive={isActive}
        />
      )}
      <StyledBottomNavText color={isActive ? 'primary' : '#71757A'} fontWeight="500" fontSize="12px">
        {label}
      </StyledBottomNavText>
    </Flex>
  );

  return showItemsOnMobile ? (
    <StyledBottomNavItem style={{ opacity: disabled ? 0.5 : 1 }} type="button" {...props}>
      {bottomNavItemContent}
    </StyledBottomNavItem>
  ) : (
    <StyledBottomNavItem style={{ opacity: disabled ? 0.5 : 1 }} as={linkComponent} href={href} {...props}>
      {bottomNavItemContent}
    </StyledBottomNavItem>
  );
};

export default BottomNavItem;
