import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
      <g clipPath="url(#clip0_2726_7433)">
        <path
          d="M12.8801 7.89987C13.3956 7.89987 13.8134 7.482 13.8134 6.96654C13.8134 6.45107 13.3956 6.0332 12.8801 6.0332C12.3646 6.0332 11.9468 6.45107 11.9468 6.96654C11.9468 7.482 12.3646 7.89987 12.8801 7.89987Z"
          fill="#71757A"
        />
        <path
          d="M14.9001 16.6671H13.5667V9.40039H11.5667C11.3899 9.40039 11.2204 9.47063 11.0953 9.59565C10.9703 9.72068 10.9001 9.89025 10.9001 10.0671C10.9001 10.2439 10.9703 10.4134 11.0953 10.5385C11.2204 10.6635 11.3899 10.7337 11.5667 10.7337H12.2334V16.6671H10.9001C10.7233 16.6671 10.5537 16.7373 10.4287 16.8623C10.3036 16.9873 10.2334 17.1569 10.2334 17.3337C10.2334 17.5105 10.3036 17.6801 10.4287 17.8051C10.5537 17.9302 10.7233 18.0004 10.9001 18.0004H14.9001C15.0769 18.0004 15.2464 17.9302 15.3715 17.8051C15.4965 17.6801 15.5667 17.5105 15.5667 17.3337C15.5667 17.1569 15.4965 16.9873 15.3715 16.8623C15.2464 16.7373 15.0769 16.6671 14.9001 16.6671Z"
          fill="#71757A"
        />
        <path
          d="M12.9001 22.6673C10.7904 22.6673 8.72811 22.0417 6.97399 20.8697C5.21986 19.6976 3.85269 18.0317 3.04535 16.0826C2.23802 14.1335 2.02678 11.9888 2.43836 9.91969C2.84994 7.85056 3.86584 5.94994 5.3576 4.45818C6.84936 2.96642 8.74998 1.95052 10.8191 1.53895C12.8882 1.12737 15.0329 1.33861 16.982 2.14594C18.9311 2.95328 20.597 4.32045 21.7691 6.07457C22.9411 7.8287 23.5667 9.89099 23.5667 12.0007C23.5667 14.8296 22.4429 17.5427 20.4425 19.5431C18.4422 21.5435 15.729 22.6673 12.9001 22.6673ZM12.9001 2.66732C11.0541 2.66732 9.24961 3.21471 7.71475 4.24027C6.17989 5.26583 4.98361 6.7235 4.27719 8.42894C3.57078 10.1344 3.38595 12.011 3.74607 13.8215C4.1062 15.632 4.99512 17.295 6.30041 18.6003C7.6057 19.9056 9.26874 20.7945 11.0792 21.1547C12.8897 21.5148 14.7663 21.33 16.4718 20.6235C18.1772 19.9171 19.6349 18.7208 20.6605 17.186C21.686 15.6511 22.2334 13.8466 22.2334 12.0007C22.2334 9.5253 21.2501 7.15133 19.4997 5.40099C17.7494 3.65065 15.3754 2.66732 12.9001 2.66732Z"
          fill="#71757A"
        />
      </g>
      <defs>
        <clipPath id="clip0_2726_7433">
          <rect width="24" height="24" fill="white" transform="translate(0.900146)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
