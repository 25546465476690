const variants = {
  warning: {
    background: 'rgba(255, 162, 58, 0.20)',
    borderColor: 'warning',
  },
  danger: {
    background: '#ED4B9E19',
    borderColor: 'failure',
  },
  success: {
    background: 'rgba(49, 208, 170, 0.1)',
    borderColor: 'success',
  },
  info: {
    background: 'rgba(91, 70, 246, 0.25)',
    borderColor: 'primary',
  },
};

export default variants;
