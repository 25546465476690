import { ChainId, WETH9, ERC20Token } from '@pancakeswap/sdk'

export const bscTokens = {
  wbnb: WETH9[ChainId.BSC],
  usdt: new ERC20Token(ChainId.BSC, '0x55d398326f99059ff775485246999027b3197955', 18, 'USDT', 'Tether USD'),
  bnb: new ERC20Token(
    ChainId.BSC,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/',
  ),
}

// import { ChainId, WETH9, ERC20Token } from '@pancakeswap/sdk'

// export const bscTokens = {
//   wbnb: WETH9[ChainId.BSC],
//   usdt: new ERC20Token(ChainId.BSC, '0x55d398326f99059ff775485246999027b3197955', 18, 'USDT', 'Tether USD'),
//   cake: new ERC20Token(ChainId.BSC, '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82', 18, 'CAKE', 'PancakeSwap Token'),
//   test: new ERC20Token(ChainId.BSC, '0x21E6e8d104950E6481FEA5925562A5Bf65Ac2436', 18, 'TEST', 'TEST Token'),
//   bnb: new ERC20Token(
//     ChainId.BSC,
//     '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
//     18,
//     'BNB',
//     'BNB',
//     'https://www.binance.com/',
//   ),
//   city: new ERC20Token(
//     ChainId.BETH,
//     '0x9320B3544296Ccf2ca32e040d669efe901B07334',
//     18,
//     'CITY',
//     'City',
//     'https://robodexswap.io',
//   ),
// }
