import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

export const WarningIcon = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none" {...props}>
    <path
      d="M50 37.4999H50.0417M82.8125 26.1249C85.7292 27.7832 87.5208 30.8874 87.5 34.2416V64.5916C87.5 67.9624 85.6542 71.0708 82.675 72.7083L54.55 90.4999C53.1555 91.2651 51.5906 91.6662 50 91.6662C48.4094 91.6662 46.8445 91.2651 45.45 90.4999L17.325 72.7083C15.8688 71.91 14.6532 70.736 13.8046 69.3085C12.956 67.881 12.5055 66.2522 12.5 64.5916V34.2374C12.5 30.8666 14.3458 27.7624 17.325 26.1249L45.45 9.54158C46.8856 8.75007 48.4982 8.33496 50.1375 8.33496C51.7768 8.33496 53.3894 8.75007 54.825 9.54158L82.95 26.1249H82.8125Z"
      stroke="#5B46F6"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M45.833 50H49.9997V66.6667H54.1663"
      stroke="#5B46F6"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
)
