import React, { useContext } from 'react';
import styled from 'styled-components';
import { variant as systemVariant, space } from 'styled-system';
import { WarningIcon, ErrorIcon, CheckmarkCircleFillIcon, InfoIcon } from '../Svg';
import { Text, TextProps } from '../Text';
import { Box } from '../Box';
import { MessageProps } from './types';
import variants from './theme';

const MessageContext = React.createContext<MessageProps>({ variant: 'success' });

const Icons = {
  warning: WarningIcon,
  danger: ErrorIcon,
  success: CheckmarkCircleFillIcon,
  info: InfoIcon,
};

const MessageContainer = styled.div<MessageProps>`
  background-color: gray;
  padding: 16px;
  /* border-radius: 16px; */
  border: solid 1px;

  ${space}
  ${systemVariant({
    variants,
  })}
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const colors = {
  // these color names should be place in the theme once the palette is finalized
  warning: '#FFA23A',
  success: '#129E7D',
  info: '#0A7ED6',
  danger: 'failure',
};

export const MessageText: React.FC<React.PropsWithChildren<TextProps>> = ({ children, ...props }) => {
  const ctx = useContext(MessageContext);
  return (
    <Text fontSize="12px" fontWeight={500} color={colors[ctx?.variant]} {...props}>
      {children}
    </Text>
  );
};

const Message: React.FC<React.PropsWithChildren<MessageProps>> = ({
  children,
  variant,
  icon,
  action,
  style,
  actionInline,
  direction,
  ...props
}) => {
  const Icon = Icons[variant];
  return (
    <MessageContext.Provider value={{ variant }}>
      <MessageContainer variant={variant} {...props}>
        <Flex style={{ flexDirection: direction, ...style }}>
          <Box {...(direction === 'row' && { mr: 1 })}>
            {icon ?? <Icon color={variants[variant].borderColor} width="2rem" />}
          </Box>
          {children}
          {actionInline && action}
        </Flex>
        {!actionInline && action}
      </MessageContainer>
    </MessageContext.Provider>
  );
};

export default Message;
