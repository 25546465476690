import { cloneElement, ElementType, isValidElement } from 'react'
import EXTERNAL_LINK_PROPS from '@pancakeswap/uikit/src/util/externalLinkProps'
import StyledButton from './StyledButton'
import { ButtonRoboticProps, scales } from './types'

const ButtonRobotic = <E extends ElementType = 'button'>(props: ButtonRoboticProps<E>): JSX.Element => {
  const {
    startIcon,
    endIcon,
    external,
    className,
    isLoading,
    disabled,
    children,
    rounded = false,
    isBlock,
    ...rest
  } = props
  const internalProps = external ? EXTERNAL_LINK_PROPS : {}
  const isDisabled = isLoading || disabled
  const classNames = className ? [className] : []

  if (isLoading) {
    classNames.push('pancake-button--loading')
  }

  if (isDisabled && !isLoading) {
    classNames.push('pancake-button--disabled')
  }

  return (
    <StyledButton
      $isLoading={isLoading}
      className={classNames.join(' ')}
      disabled={isDisabled}
      style={{
        ...(isBlock && {
          width: '100%',
        }),
      }}
      {...internalProps}
      {...rest}
    >
      <>
        {isValidElement(startIcon) &&
          cloneElement(startIcon, {
            // @ts-ignore
            mr: '0.5rem',
          })}

        <img src="/bg-button.png" alt="Button Icon" style={{ position: 'absolute', width: '100%', height: '100%' }} />
        {children}
        {isValidElement(endIcon) &&
          cloneElement(endIcon, {
            // @ts-ignore
            ml: '0.5rem',
          })}
      </>
    </StyledButton>
  )
}

ButtonRobotic.defaultProps = {
  isLoading: false,
  external: false,
  scale: scales.MD,
  disabled: false,
  shouldResponsive: true,
}

export default ButtonRobotic
