import { ChainId } from '@pancakeswap/sdk'
import { isStableFarm, SerializedFarmConfig } from '@pancakeswap/farms'

let farmingDataCache: SerializedFarmConfig[] | null = null
export const getFarmConfig = async (chainId: ChainId) => {
  const farmConfigApi = process.env.NEXT_PUBLIC_STAKING_API_URL || 'https://robo.nusabyte.com'
  let logged = false

  const fetchFarmData = async (url: string, options: RequestInit = {}) => {
    const response = await fetch(url, options)
    if (!response.ok) {
      throw new Error(`Failed to fetch data from ${url}`)
    }
    return response.json()
  }

  // Set loading status to true before starting fetch
  let loading = true

  try {
    const farmList = await fetchFarmData(`${farmConfigApi}/farms`)
    const farmSuccess = farmList
    const modifiedFarmList = farmSuccess.map((farm, index) => ({
      pid: index,
      lpSymbol: `${farm.lpSymbol?.replace(' - ', '-') || ''} LP`,
      lpAddress: farm.lpAddress || '',
      token: farm.token
        ? {
            address: farm.token.address || '',
            chainId: farm.token.chainId || 1605,
            decimals: Number(farm.token.decimals) || '',
            symbol: farm.token.symbol || '',
            name: (farm.token.name || '').replace(' Token', ''),
            projectLink: '',
          }
        : {
            address: '',
            chainId: '',
            decimals: '',
            symbol: '',
            name: '',
            projectLink: '',
          },
      quoteToken: farm.quoteToken
        ? {
            address: farm.quoteToken.address || '',
            chainId: farm.quoteToken.chainId || 1605,
            decimals: Number(farm.quoteToken.decimals) || '',
            symbol: farm.quoteToken.symbol || '',
            name: farm.quoteToken.name || '',
            projectLink: '',
          }
        : {
            address: '',
            chainId: '',
            decimals: '',
            symbol: '',
            name: '',
            projectLink: '',
          },
      owner: farm.owner,
      status: farm.status,
    }))

    const returnData = modifiedFarmList.filter((f: SerializedFarmConfig) => f.pid !== null) as SerializedFarmConfig[]
    farmingDataCache = returnData

    // Set loading to false after successful fetch
    loading = false

    return {
      data: returnData,
      loading,
      error: null,
    }
  } catch (error) {
    if (!logged) {
      // console.error('Cannot get farm config', error, chainId)
      logged = true
    }

    // Set loading to false if there's an error
    loading = false

    return {
      data: farmingDataCache || [],
      loading,
      error: error.message,
    }
  }
}
