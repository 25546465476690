import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        opacity="0.3"
        d="M5.95996 25.3334H26.04L16 7.98669L5.95996 25.3334ZM17.3333 24H14.6666V21.3334H17.3333V24ZM17.3333 18.6667H14.6666V13.3334H17.3333V18.6667Z"
        fill="#FFA23A"
      />
      <path
        d="M1.33301 28H30.6663L15.9997 2.66669L1.33301 28ZM5.95967 25.3334L15.9997 7.98669L26.0397 25.3334H5.95967ZM14.6663 21.3334H17.333V24H14.6663V21.3334ZM14.6663 13.3334H17.333V18.6667H14.6663V13.3334Z"
        fill="#FFA23A"
      />
    </Svg>
  );
};

export default Icon;
