import { useTranslation } from '@pancakeswap/localization';
import { useIsMounted } from '@pancakeswap/hooks';
import { PropsWithChildren, ReactNode } from 'react';
import { AutoColumn, RowBetween, Text, TextProps } from '../../components';

type SwapInfoType = {
  price: ReactNode;
  allowedSlippage: number;
  showSlip?: boolean;
};

export const SwapInfoLabel = (props: PropsWithChildren<TextProps>) => <Text fontSize={12} {...props} />;

export const SwapInfo = ({ allowedSlippage, price, showSlip = true }: SwapInfoType) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  return (
    <AutoColumn gap="sm">
      <RowBetween alignItems="center">{price}</RowBetween>
      {showSlip && (
        <RowBetween alignItems="center">
          <SwapInfoLabel>{t('Slippage Tolerance')}</SwapInfoLabel>
          {isMounted && (
            <Text small semiBold>
              {allowedSlippage / 100}%
            </Text>
          )}
        </RowBetween>
      )}
    </AutoColumn>
  );
};
