/* eslint-disable import/prefer-default-export */
import BigNumber from 'bignumber.js'
import sousChefV2 from 'config/abi/sousChefV2.json'
import multicall from '../multicall'
import { getAddress } from '../addressHelpers'
import { ChainId } from '@pancakeswap/sdk'
import store from 'state'

let stakingDataCache: []
export const fetchStakingJson = async () => {
  const route = window.location.pathname
  const dataStaking = store.getState().pools.myStakeData
  if (route.includes('/pools') && dataStaking && dataStaking.length === stakingDataCache?.length) {
    return stakingDataCache
  }
  if (route.includes('/my-stake') && dataStaking && dataStaking.length === stakingDataCache?.length) {
    return stakingDataCache
  }

  try {
    const response = await fetch('/data/staking.json')

    if (!response.ok) {
      throw new Error('Failed to fetch staking data')
    }

    const data = await response.json()

    stakingDataCache = data
    return data
  } catch (error) {
    console.error('Error fetching staking data:', error)
    return stakingDataCache
  }
}

export const getActivePools = async (chainId: ChainId, block: number) => {
  const data = await fetchStakingJson()
  const livePools = data ?? []
  const finishedPools = [].map((p) => ({
    ...p,
    isFinished: true,
    // stakingToken: p.stakingToken.serialize,
    // earningToken: p.earningToken.serialize,
  }))
  const poolsConfig = [...livePools, ...finishedPools]
  const eligiblePools = poolsConfig
    .filter((pool) => chainId in pool.contractAddress)
    .filter((pool) => pool.sousId !== 0)
    .filter((pool) => pool.isFinished === false || pool.isFinished === undefined)
  const blockNumber = block
  const startBlockCalls = eligiblePools.map(({ contractAddress }) => ({
    address: getAddress(contractAddress, chainId),
    name: 'startBlock',
  }))
  const endBlockCalls = eligiblePools.map(({ contractAddress }) => ({
    address: getAddress(contractAddress, chainId),
    name: 'bonusEndBlock',
  }))
  const [startBlocks, endBlocks] = await Promise.all([
    multicall(sousChefV2, startBlockCalls, chainId),
    multicall(sousChefV2, endBlockCalls, chainId),
  ])

  return eligiblePools.reduce((accum, poolCheck, index) => {
    const startBlock = startBlocks[index] ? new BigNumber(startBlocks[index]) : null
    const endBlock = endBlocks[index] ? new BigNumber(endBlocks[index]) : null

    if (!startBlock || !endBlock) {
      return accum
    }

    if (startBlock.gte(blockNumber) || endBlock.lte(blockNumber)) {
      return accum
    }

    return [...accum, poolCheck]
  }, [])
}
