import React from 'react';
import styled, { keyframes } from 'styled-components';
import { SpinnerProps } from './types';

// Keyframes for the animations
const rotate4 = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash4 = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
`;

// Styled component for the SVG
const SpinnerWrapper = styled.svg`
  width: 6.25em;
  transform-origin: center;
  animation: ${rotate4} 2s linear infinite;
`;

// Styled component for the Circle inside the SVG
const SpinnerCircle = styled.circle`
  fill: none;
  stroke: ${({ theme }) => theme.colors.primary};
  stroke-width: 4;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: ${dash4} 1.5s ease-in-out infinite;
`;

const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = ({ size = 128 }) => {
  return (
    <SpinnerWrapper viewBox="25 25 50 50">
      <SpinnerCircle cx="50" cy="50" r="20" />
    </SpinnerWrapper>
  );
};

export default Spinner;
