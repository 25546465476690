import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
      <path
        d="M8.49967 1.33334C4.81967 1.33334 1.83301 4.32001 1.83301 8.00001C1.83301 11.68 4.81967 14.6667 8.49967 14.6667C12.1797 14.6667 15.1663 11.68 15.1663 8.00001C15.1663 4.32001 12.1797 1.33334 8.49967 1.33334ZM9.16634 11.3333H7.83301V7.33334H9.16634V11.3333ZM9.16634 6.00001H7.83301V4.66668H9.16634V6.00001Z"
        fill="#FFA23A"
      />
    </Svg>
  );
};

export default Icon;
